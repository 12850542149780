<template>
  <div class="address-edit">
    <page-nav :title="isEdit ? '收货地址编辑' : '新增收货地址'" />
    <van-form @submit="onSubmit" validate-trigger="onSubmit">
      <van-field
        v-model="name"
        name="name"
        label="收货人"
        placeholder="请输入"
        maxlength="10"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="电话号码"
        placeholder="请输入"
        maxlength="11"
        type="number"
      />  
      <van-field
        v-model="locationText"
        name="location"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="showPicker = true"
      />
      <van-field
        name="addressDetail"
        v-model="addressDetail"
        rows="2"
        autosize
        label="详细地址"
        type="textarea"
        maxlength="100"
        placeholder="请输入"
      />
      <div class="actions">
        <van-button v-if="isEdit && showDefault" block round type="primary" color="#999" native-type="button" @click="onSetDefault">设为默认地址</van-button>
        <van-button block round type="info" native-type="submit">保存</van-button>
        <van-button v-if="isEdit" block round type="danger" native-type="button" @click="onDelete">删除</van-button>
      </div>
    </van-form>
    <van-popup v-model="showPicker" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="address_options"
        @close="showPicker = false"
        @finish="onFinishAddress"
        @change="onChangeAddress"
        :field-names="fieldNames"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import utils from '../../common/utils';
import { Toast, Form, Field, Cascader, Popup, Dialog } from 'vant';
import Fetch from '@/common/fetch';
import { GetAddressDetail, GetAreaList, AddAddress, ModifyAddress, AddressSetDefault, DeleteAddress } from '@/common/interface';
import PageNav from '@/components/PageNav.vue';
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Cascader);
Vue.use(Popup);
Vue.use(Dialog);

export default {
  name: 'AddressEdit',
  components: { PageNav },
  data() {
    return {
      name: '',
      phone: '',
      addressDetail: '',
      isDefault: false,
      showPicker: false,
      address_options: [],
      locationText: '',
      cascaderValue: '',
      location: [],
      fieldNames: {
        text: 'label',
      },
      isEdit: false,
      showDefault: false,
    };
  },
  created() {
    if (utils.getQueryString('adid')) {
      this.isEdit = true;
      this.fetchEditDetail();
    } 
    utils.getCityList().then((result) => {
      this.address_options = result;
    });
  },
  methods: {
    fetchEditDetail() {
      const id = utils.getQueryString('adid');
      Fetch.post(GetAddressDetail, {id}).then(res => {
        this.name = res.name;
        this.phone = res.phone;
        this.addressDetail = res.addressDetail;
        this.location = [res.provId,res.cityId,res.areaId];
        this.locationText = res.provName + '/' + res.cityName + '/' + res.areaName;
        this.showDefault = res.isDefault != 1;
      }).catch((err) => {
        Toast(err.msg);
      })
    },
    onChangeAddress(item) {
      const { selectedOptions = [], value } = item;
      const selectedOption = selectedOptions[selectedOptions.length - 1];
      if (selectedOption.level == 2) {
        const params = {
          cityId: value,
        };
        Fetch.post(GetAreaList, params).then(res => {
          selectedOption.children = res;
        }).catch(() => {
        })
      }
    },

    onFinishAddress(item) {
      const { selectedOptions, tabIndex } = item;
      if (tabIndex == 2) {
        this.showPicker = false;
        this.locationText = selectedOptions.map((option) => option.label).join('/');
        this.location = selectedOptions.map((option) => option.value);
      }
    },

    onSetDefault() {
      const id = utils.getQueryString('adid') || '';
      const params = {
        id,
      };
      Fetch.post(AddressSetDefault,params).then(() => {
        this.$router.go(-1);
      }).catch(err => {
        Toast(err.msg || '请求失败')
      })
    },

    onDelete() {
      Dialog.confirm({
        title: '确认要删除此收货地址吗？',
        confirmButtonText: '删除',
      })
      .then(() => {
        const id = utils.getQueryString('adid') || '';
        Fetch.post(DeleteAddress, {id}).then(() => {
          this.$router.go(-1);
        }).catch((err) => {
          Toast(err.msg || '请求失败');
        });
      }).catch(() => {
      });
    },

    onSubmit(values) {
      const params = {
        ...values,
        provId: this.location[0],
        cityId: this.location[1],
        areaId: this.location[2],
      };
      let api = AddAddress;
      const id = utils.getQueryString('adid') || '';
      // 有id为编辑
      if (id) {
        params.id = id;
        api = ModifyAddress;
      }
      Fetch.post(api, params).then(() => {
        this.$router.go(-1);
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.address-edit {
  .actions {
    margin: 20px 0;
    padding: 0 16px;
    button {
      margin-bottom: 8px;
    }
  }
}
</style>